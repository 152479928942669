import React from 'react';
import {
  BarcodeIcon,
  BuildingIcon,
  CalendarIcon,
  DPadIcon,
  GearIcon,
  GlobeIcon,
  GroupIcon,
  StorageIcon,
  SwitchLiteIcon,
  SkuIcon,
  Heading,
  Link,
  ScreenReaderOnly,
  WrenchIcon,
} from '@nintendo-of-america/component-library';
import { fileSize, isProductDigital } from '@local/lib/helpers';
import { Grid } from '@shared/ui';
import { getHighestContentRatingCode } from '@shared/util';
import { useLocalizer } from '@nintendo-of-america/react-hooks';
import {
  TOP_LEVEL_CATEGORY_CODE,
  FILTERABLE_ATTRIBUTES,
} from '@nintendo-of-america/search';

import * as S from './ProductInfo.styles';

const InfoRow = ({ icon, heading, descriptions }) => {
  return (
    <S.InfoRow>
      {icon}
      <Heading variant="h3">{heading}</Heading>
      <S.InfoDescr>
        {descriptions.map((descr) => (
          <div key={descr.label}>
            {descr.href ? (
              <Link href={descr.href}>{descr.label}</Link>
            ) : (
              descr.label
            )}
          </div>
        ))}
      </S.InfoDescr>
    </S.InfoRow>
  );
};

const ProductInfo = ({ product }) => {
  const { date, DateFormat, text } = useLocalizer();
  const searchPrefix = '/search';

  const genres = product.genres?.map((genre) => ({
    href: `${searchPrefix}/#cat=gme&f=genres&genres=${encodeURIComponent(
      genre.label
    )}`,
    label: genre.label,
  }));
  const playModes = product.playModes?.map((playMode) => text(playMode.label));
  const playerSupport = getPlayerSupport(searchPrefix, product, text);
  const isDigital = isProductDigital(product);

  const isHardware =
    product?.topLevelCategory?.code === TOP_LEVEL_CATEGORY_CODE.HARDWARE;

  return (
    <S.InfoSection>
      <ScreenReaderOnly id="product-info">
        <Heading>{text('Product information')}</Heading>
      </ScreenReaderOnly>
      <Heading.NewLevel>
        <Grid columns={1} gap={0}>
          {product.releaseDate && (
            <InfoRow
              icon={<CalendarIcon size={28} />}
              heading={text('Release date')}
              descriptions={[
                {
                  label: product.releaseDateDisplay
                    ? product.releaseDateDisplay
                    : date(product.releaseDate, { format: DateFormat.LONG }),
                },
              ]}
            />
          )}
          {product.playersMax && (
            <InfoRow
              icon={<GroupIcon size={28} />}
              heading={text('No. of players')}
              descriptions={playerSupport}
            />
          )}
          {product.genres && (
            <InfoRow
              icon={<DPadIcon size={28} />}
              heading={text('Genre')}
              descriptions={genres}
            />
          )}
          {product.softwarePublisher && (
            <InfoRow
              icon={<BuildingIcon size={28} />}
              heading={text('Publisher')}
              descriptions={[
                {
                  href: `${searchPrefix}#cat=gme&f=softwarePublisher&softwarePublisher=${encodeURIComponent(
                    product.softwarePublisher
                  )}`,
                  label: product.softwarePublisher,
                },
              ]}
            />
          )}
          {product.softwareDeveloper && (
            <InfoRow
              icon={<WrenchIcon size={28} />}
              heading={text('Developer')}
              descriptions={[
                {
                  href: `${searchPrefix}#cat=gme&f=softwareDeveloper&softwareDeveloper=${encodeURIComponent(
                    product.softwareDeveloper
                  )}`,
                  label: product.softwareDeveloper,
                },
              ]}
            />
          )}
          {product.contentRating != null && (
            <InfoRow
              icon={<GearIcon size={28} />}
              heading={text('ESRB rating')}
              descriptions={[
                {
                  href: `${searchPrefix}#cat=gme&sort=df&f=${FILTERABLE_ATTRIBUTES.CONTENT_RATING}&${FILTERABLE_ATTRIBUTES.CONTENT_RATING}=${encodeURIComponent(
                    getHighestContentRatingCode(product)
                  )}`,
                  label: product.contentRating.label,
                },
              ]}
            />
          )}
          {product.playModes && (
            <InfoRow
              icon={<SwitchLiteIcon size={28} />}
              heading={text('Supported play modes')}
              descriptions={[{ label: playModes.join(', ') }]}
            />
          )}
          {product.romFileSize && (
            <InfoRow
              icon={<StorageIcon size={28} />}
              heading={text('Game file size')}
              descriptions={[{ label: fileSize(product.romFileSize) }]}
            />
          )}
          {product.supportedLanguages && (
            <InfoRow
              icon={<GlobeIcon size={28} />}
              heading={text('Supported languages')}
              descriptions={[
                {
                  label: product.supportedLanguages?.map(text).join(', '),
                },
              ]}
            />
          )}
          {product.countryOfOrigin && (
            <InfoRow
              icon={<GlobeIcon size={28} />}
              heading={text('Country of origin')}
              descriptions={[{ label: product.countryOfOrigin }]}
            />
          )}
          {product.manufacturer && (
            <InfoRow
              icon={<BuildingIcon size={28} />}
              heading={text('Manufacturer')}
              descriptions={[
                {
                  href: `${searchPrefix}#cat=${isHardware ? 'hdw' : 'merch'}&f=manufacturer&manufacturer=${encodeURIComponent(
                    product.manufacturer
                  )}&p=1&sort=df`,
                  label: product.manufacturer,
                },
              ]}
            />
          )}
          {product.sku && !isDigital && (
            <InfoRow
              icon={<SkuIcon size={28} />}
              heading={text('SKU')}
              descriptions={[{ label: product.sku }]}
            />
          )}
          {product.upc && !product.nsuid && (
            <InfoRow
              icon={<BarcodeIcon size={28} />}
              heading={text('UPC')}
              descriptions={[{ label: product.upc }]}
            />
          )}
        </Grid>
      </Heading.NewLevel>
    </S.InfoSection>
  );
};

const getPlayerSupport = (searchPrefix, product, text) => {
  let result = [];
  if (product.playersMax) {
    result.push(
      playerRange(
        text('Single System'),
        product.playersMin,
        product.playersMax,
        { searchPrefix, text }
      )
    );
  }
  if (product.playersMaxLocal) {
    result.push(
      playerRange(
        text('Local wireless'),
        product.playersMinLocal,
        product.playersMaxLocal,
        { searchPrefix, text }
      )
    );
  }
  if (product.playersMaxOnline) {
    result.push(
      playerRange(
        text('Online'),
        product.playersMinOnline,
        product.playersMaxOnline,
        { searchPrefix, text }
      )
    );
  }
  return result;
};

const playerRange = (label, min, max, options) => {
  const { searchPrefix, text } = options;
  const players = parseInt(max || min);

  const filterLabel =
    players === 1 ? text('Single Player') : players > 3 ? '4+' : `${players}+`;

  return {
    href: `${searchPrefix}#cat=gme&f=playerCount&playerCount=${encodeURIComponent(
      filterLabel
    )}`,
    label:
      label + ' (' + (min != max ? min + '-' : '') + (max ? max : '') + ')',
  };
};

export default ProductInfo;
